import React from 'react';
import styles from './dropdownActions.module.scss';
import { cnx } from '../../utils/classNameUtils';

export interface IProps {
    title?: string;
    className?: string;
}

const DropdownActionsSection = (props: IProps) => {
    const {
        title,
        className,
    } = props;

    const sectionClassName = cnx([
        styles['section'],
        className,
    ]);

    return (
        <div className={sectionClassName}>
            {title}
        </div>
    );
};

export default DropdownActionsSection;
