export function capitalize(text: string): string {
    if (!text || text.length === 0) {
        return text;
    }

    if (text.length === 1) {
        return text.toUpperCase();
    }

    const prepared = text
        .trim()
        .toLowerCase();

    return prepared.substring(0, 1).toUpperCase() + prepared.substring(1);
}
