import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConnectedSmsCodeApproveContent from '../../../ConnectedSmsCodeApproveContent/ConnectedSmsCodeApproveContent';
import {
    changePhoneNumber,
    commonRegistrationModalHandler,
} from '@app/ducks/common/registration';
import AuthWizardHeader, { IAuthWizardHeaderProps } from '@app/components/client/authentication/AuthWizardHeader/AuthWizardHeader';

const StepSmsForm: React.FC<IAuthWizardHeaderProps> = () => {
    const dispatch = useDispatch();
    const didPhoneNumberChanged = useCallback(() => dispatch(changePhoneNumber()), []);
    const didCloseButtonClicked = useCallback(() => dispatch(commonRegistrationModalHandler.close()), []);

    return (
        <>
            <AuthWizardHeader
                back
                onBackClick={didPhoneNumberChanged}
            />
            <ConnectedSmsCodeApproveContent allowRetry onClose={didCloseButtonClicked} />
        </>
    );
};

export default StepSmsForm;