import React from 'react';
import Image from '../../Image/Image';
import styles from './appBadge.module.scss';

export interface IProps {
    src: string;
    href: string;
    title: string;
}

const AppBadgeButton = (props: IProps) => (
    <a
        className={styles['button']}
        href={props.href}
        target="_blank"
        rel="noreferrer noopener nofollow"
    >
        <Image src={props.src} alt={props.title} fill />
    </a>
);

export default AppBadgeButton;