import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import SmsCodeApproveContent, { ISharedProps } from '@frontend/jetlend-web-ui/src/components/SmsCodeApproveContent/SmsCodeApproveContent';

import {
    confirmSmsDialog,
    form,
    getSmsState,
    retrySmsDialog,
} from '@app/ducks/common/sms';

export default connectFunctionalComponent(SmsCodeApproveContent, {
    selectors: {
        smsState: getSmsState,
        formValues: form.formValues,
    },
    dispatch: {
        onSubmit: confirmSmsDialog,
        onRetry: retrySmsDialog,
    },
    rawForm: form as any,
}) as React.FC<ISharedProps & { onClose: () => void }>;