import React, { ReactNode } from 'react';
import { cnx } from '@ui/utils/classNameUtils';
import styles from './dropdownActions.module.scss';

interface IProps {
    children: ReactNode;

    href: string;
    external?: boolean;
    className?: string;
    noFollow?: boolean;
}

const DropdownActionsLink = (props: IProps) => {
    const {
        children,
        href,
        external = false,
        noFollow = false,
        className,
    } = props;

    const buttonClassName = cnx([
        styles['button'],
        className,
    ]);

    function getRelValue() {
        if (external) {
            return 'noopener noreferrer nofollow';
        }

        if (noFollow) {
            return 'nofollow';
        }

        return undefined;
    }

    return (
        // Нужные свойства безопасности устанавливаются через функцию, eslint их не видит, поэтому используем атрибут
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            type="button"
            href={href}
            rel={getRelValue()}
            target={external ? '_blank' : undefined}
            className={buttonClassName}
        >
            {children}
        </a>
    );
};

export default DropdownActionsLink;
