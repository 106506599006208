'use client';

import React, {
    PropsWithChildren,
    useCallback,
    useContext,
} from 'react';
import { LayoutHeaderContext } from './LayoutHeaderPortalRenderer';
import { capitalize } from '@app/utils/capitalize';
import Image from '@app/components/server/Image/Image';

import styles from './layoutHeader.module.scss';
import backIcon from '@app/icons/icon-sub-menu-back.svg';

export interface IProps extends PropsWithChildren {
    /**
     * Заголовок по которому отображаем подменю
     */
    title: string;
};

/**
 * Компонент подменю
 */
export default function LayoutHeaderSubMenu({
    children,
    title,
}: IProps) {
    const { changeVisibility } = useContext(LayoutHeaderContext);

    const didBackClicked = useCallback(() => {
        changeVisibility();
    }, [changeVisibility]);

    return (
        <div className={styles['sub-menu-container']}>
            <div className={styles['sub-menu-title']} onClick={didBackClicked}>
                <div>
                    <Image src={backIcon} alt="" fill />
                </div>
                {capitalize(title)}
            </div>
            <div className={styles['sub-menu-links']}>
                {children}
            </div>
        </div>
    );
};
