import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../ui/TooltipIcon/Tooltip';
import {
    buildClassNames,
    mergeClassNames,
} from '../utils/classNameUtils';

import styles from './headerButton.module.scss';

export interface IProps extends React.PropsWithChildren {
    title?: string;
    hint?: string;
    icon?: string;
    active?: boolean;
    type?: 'button';
    href?: string;
    onClick?: (e: React.MouseEvent) => void;
    children?: React.ReactNode;
    size?: 'small' | 'large';
    noMargin?: boolean;
    useBadge?: boolean;
    badge?: number;
    adaptFirst?: boolean;
    itemClassName?: string;
    childrenPosition?: 'left' | 'right';
}

const HeaderButton = React.forwardRef<any, IProps>(
    ({
        title,
        hint,
        icon,
        active,
        type,
        href,
        size = 'small',
        noMargin,
        useBadge,
        badge = undefined,
        adaptFirst,
        onClick,
        children,
        itemClassName,
        childrenPosition = 'right',
    }, ref) => {

        const builtItemClassName = buildClassNames(styles, [
            'item',
            active && 'item--active',
            noMargin && 'item--no-margin',
            `item--${size}`,
            (!title || title.length === 0) && 'item--without-text',
            adaptFirst && 'item--removed',
        ]);
        const mergedItemClassName = mergeClassNames([builtItemClassName, itemClassName]);

        const badgeClassName = buildClassNames(styles, [
            'item__badge',
            (typeof badge === 'number' && badge > 0) && 'item__badge--active',
        ]);

        const titleClassName = buildClassNames(styles, [
            'title',
            adaptFirst && 'title--removed',
        ]);

        const didClicked = useCallback(e => onClick && onClick(e), [onClick]);

        function getBadgeLabel(): string {
            if (!badge) {
                return '0';
            }

            if (badge > 99) {
                return '99+';
            }

            return badge.toString();
        }

        const innerContent = (
            <>
                {childrenPosition === 'left' && children}
                {useBadge &&
                    <span className={badgeClassName}>{getBadgeLabel()}</span>
                }
                {icon &&
                    <img src={icon} className={styles['icon']} alt="" />
                }
                {title &&
                    <span className={titleClassName}>{title}</span>
                }
                {childrenPosition === 'right' && children}
            </>
        );

        if (type === undefined && !href) {
            type = 'button';
        }

        const control = type === 'button'
            ? <button ref={ref as any} type="button" className={mergedItemClassName} onClick={didClicked}>{innerContent}</button>
            : <Link ref={ref as any} className={mergedItemClassName} to={href} onClick={didClicked}>{innerContent}</Link>;

        return <Tooltip title={hint}>{control}</Tooltip>;
    });

export default HeaderButton;