import React, {
    PropsWithChildren,
    useCallback,
} from 'react';
import DropdownActions, { IProps as IDropdownActionsProps } from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import menuIcon from '@frontend/jetlend-assets/icons/header-menu-arrow.svg';
import menuMobileIcon from '@frontend/jetlend-assets/icons/header-menu-arrow--mobile.svg';
import { buildClassNames } from '../../utils/classNameUtils';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import {
    isMobile,
    useWindowSize,
} from '@ui/utils/responsive';

import styles from './responsiveMenu.module.scss';

export interface IActionItem {
    /**
     * Заголовок
     */
    title: string;
    /**
     * Ссылка
     */
    href: string;
}

export interface IProps extends IActionItem, Pick<IDropdownActionsProps, 'placement' | 'detached' | 'zIndex'>, PropsWithChildren {
    /**
     * Атрибут rel ссылки
     */
    rel?: string;
    /**
     * Активна ли ссылка
     */
    active?: boolean;
    /**
     * Событие клика стрелки выпадающего списка
     */
    onDropdownArrowClick?: () => void;
}

/**
 * Компонент меню с выпадающим списком
 */
function ResponsiveMenuDropdownAction({
    title,
    href,
    rel,
    active,
    onDropdownArrowClick,
    children,
    placement='bottom',
    detached,
    zIndex,
}: IProps, ref) {
    const { width } = useWindowSize();
    const isMobileScreen = isMobile(width);

    const icon = isMobileScreen ? menuMobileIcon : menuIcon;
    const isDropdownListVisible = (children && React.Children.count(children) > 0) || false;

    const linkClassName = buildClassNames(styles, [
        'dropdown',
        active && 'dropdown--active',
    ]);

    const didDropdownArrowClicked = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (onDropdownArrowClick) {
            onDropdownArrowClick();
        }
    }, [onDropdownArrowClick]);

    return (
        <DropdownActions
            placement={placement}
            detached={detached}
            zIndex={zIndex}
            size="small"
            containerClassName={styles['button']}
            hover={!isMobileScreen && isDropdownListVisible}
            withoutArrow={false}
            button={(
                <a className={linkClassName} ref={ref} href={href} rel={rel}>
                    {title}
                    {isDropdownListVisible &&
                        <div className={styles['dropdown-icon-wrapper']} onClick={didDropdownArrowClicked}>
                            <img src={getAssetSrc(icon)} alt="" />
                        </div>
                    }
                </a>
            )}
        >
            {children}
        </DropdownActions>
    );
};

export default React.forwardRef(ResponsiveMenuDropdownAction);