import { useSelector } from 'react-redux';
import { IModalHandler } from '../ducks/modal';
import { useActions } from './useActions';

type HookReturnType = [
    boolean,
    () => void,
    () => void
]

/**
 * Хук для управления одиночным модальным окном {@link modalHandler} или {@link modalFormHandler} внутри компонента.
 *
 * @param handler Хендлер модального окна.
 * @returns Возвращает флаг открытия модального окна, и функции для открытия и закрытия.
 * @category Hooks
 *
 * @see {@link useIsModalOpen}
 * @see {@link useIsModalOpenWithKey}
 * @see {@link useModalHandlerWithKey}
 *
 * @example
 * const [ isOpen, open, close ] = useModalHandler(sprintCreateModalHandler);
 */
export default function useModalHandler(handler: IModalHandler): HookReturnType {
    const isOpen = useSelector(handler.isOpen) === true;
    const [ open, close ] = useActions([
        handler.open,
        handler.close,
    ]);

    return [
        isOpen,
        open,
        close,
    ];
}