export enum AccountType {
    Base = 10,
    Regular = 15,
    Premium = 20,
    Ultra = 30,
    Green = 40,
    Bronze = 50,
    Silver = 60,
    Gold = 70,
    Platinum = 80,
    Diamond = 90,
}

export const ACCOUNT_TYPE_MAP: Record<AccountType, string> = {
    [ AccountType.Base ]: 'Base',
    [ AccountType.Regular ]: 'Standard',
    [ AccountType.Premium ]: 'Premium',
    [ AccountType.Ultra ]: 'Ultra',
    [ AccountType.Green ]: 'Green',
    [ AccountType.Bronze ]: 'Bronze',
    [ AccountType.Silver ]: 'Silver',
    [ AccountType.Gold ]: 'Gold',
    [ AccountType.Platinum ]: 'Platinum',
    [ AccountType.Diamond ]: 'Diamond',
};

export interface IProps {
    type: AccountType;
    link?: string;
}

export function getBadgeClassName(type: AccountType) {
    switch (type) {
    case AccountType.Base:
        return 'badge--type-base';
    case AccountType.Regular:
        return 'badge--type-regular';
    case AccountType.Premium:
        return 'badge--type-premium';
    case AccountType.Ultra:
        return 'badge--type-ultra';
    case AccountType.Bronze:
        return 'badge--type-bronze';
    case AccountType.Diamond:
        return 'badge--type-diamond';
    case AccountType.Gold:
        return 'badge--type-gold';
    case AccountType.Platinum:
        return 'badge--type-platinum';
    case AccountType.Silver:
        return 'badge--type-silver';
    default:
        return 'badge--type-regular';
    }
}