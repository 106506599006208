import { useSelector } from 'react-redux';
import { IFormHandler } from '@core/ducks/form';
import { useActions } from './useActions';

/**
 * Experimental. Use with caution.
 *
 * React хук для работы с хендлером форм {@link IFormHandler} для быстрой отправки запроса и получения результата.
 * Удобно использовать для работы с АПИ запросами, которые необходимо вызывать при определенных событиях.
 *
 * Не требует подключение всего компонента к форме. Он работает только при вызове метода {@link IFormHandler.submit}
 *
 * @param form Инстанс хендлера формы
 * @returns Функцию для отправки данных, состояние отправки данных, результат запроса и ошибку
 *
 * @category Hooks
 *
 * @example
 * const [ submit, isFormSubmitting, response, error ] = useFormSubmitter(formHandler);
 *
* const didButtonClicked = useCallback(() => {
 *      submit({
 *            name: 'test',
 *            age: 10,
 *      });
 * }, [ submit ]);
 *
 * return (
 *      <Button text="Click to send" loading={isFormSubmitting} onClick={didButtonClicked} />
 *      <FormAlert alert={error} />
 *      <div>
 *          {JSON.stringify(response)}
 *      </div>
 * );
 */
export default function useFormSubmitter(form: IFormHandler) {
    const submit = useActions(form.submit);
    const error = useSelector(form.formError);
    const response = useSelector(form.selector);
    const isFormSubmitting = useSelector(form.formSubmitting);

    return [
        submit,
        isFormSubmitting,
        response,
        error,
    ];
}
