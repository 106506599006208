'use client';

import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import Button, { ButtonProps } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, { useCallback } from 'react';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';

import styles from './RegistrationButton.module.scss';

/**
 * Кнопка регистрации
 */
export default function RegistrationButton(props: ButtonProps) {
    const startRegistrationAction = useActions(startRegistration);
    const didInvestorStartClicked = useCallback(() => startRegistrationAction(ClientType.Investor), [startRegistrationAction]);
    const didBorrowerStartClicked = useCallback(() => startRegistrationAction(ClientType.Borrower), [startRegistrationAction]);
    const didPartnerStartClicked = useCallback(() => startRegistrationAction(ClientType.Partner), [startRegistrationAction]);

    return (
        <DropdownActions
            placement="bottom"
            size="small"
            hover
            withoutArrow={false}
            button={(
                <Button
                    text="Зарегистрироваться"
                    noSpacing
                    width={130}
                    {...props}
                />
            )}
        >
            <DropdownActions.Button onClick={didInvestorStartClicked} className={styles['button']}>
                Инвестор
            </DropdownActions.Button>
            <DropdownActions.Button onClick={didBorrowerStartClicked} className={styles['button']}>
                Предприниматель
            </DropdownActions.Button>
            <DropdownActions.Button onClick={didPartnerStartClicked} className={styles['button']}>
                Партнер
            </DropdownActions.Button>
        </DropdownActions>
    );
};
