import React, { useCallback } from 'react';
import { buildClassNames } from '../utils/classNameUtils';

import styles from './headerBurgerButton.module.scss';
import HeaderButton from './HeaderButton';

export interface IProps {
    opened?: boolean;
    inverted?: boolean;
    /**
     * Убрать отступы справа
     */
    noMargin?: boolean;
    onClick?: () => void;
}

const HeaderBurgerButton: React.FC<IProps> = props => {
    const {
        onClick,
        opened,
        inverted,
        noMargin,
    } = props;

    const didClicked = useCallback(() => onClick && onClick(), [ onClick ]);

    const menuButtonClassName = buildClassNames(styles, [
        'menu-button',
        inverted && 'menu-button--inverted',
        opened ? 'active' : null,
    ]);

    return (
        <HeaderButton noMargin={noMargin} onClick={didClicked}>
            <div className={menuButtonClassName}>
                <span />
                <span />
                <span />
            </div>
        </HeaderButton>
    );
};

export default HeaderBurgerButton;
