'use client';

import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { IPagesMenuApiModel } from '@app/models/pages/pages';
import LayoutHeaderSubMenu from './LayoutHeaderSubMenu';

export interface IContextProps {
    /**
     * Идентификатор выбранной строки меню
     */
    id: number;
    /**
     * Отображается ли подменю
     */
    isVisible: boolean;
    /**
     * Функция добавления идентификатора выбранной строки
     */
    setId: (id: number) => void;
    /**
     * Функция изменения видимости подменю
     */
    changeVisibility: () => void;
}

export interface IPortalProps extends PropsWithChildren {
    /**
     * Навигация хедера
     */
    navigation: IPagesMenuApiModel[];
    /**
     * Флаг мобильной версии
     */
    isMobile?: boolean;
    /**
     * Класс для контейнера
     */
    containerClassName?: string;
    /**
     * Класс для ссылки
     */
    linkClassName?: string;
}

export const LayoutHeaderContext = React.createContext<IContextProps>({
    id: null,
    isVisible: false,
    setId: () => undefined,
    changeVisibility: () => undefined,
});

/**
 * Компонент-обертка с подпиской на контекст меню хедера
 */
export default function LayoutHeaderPortalProvider({ children }: PropsWithChildren) {
    const [id, setId] = useState(null);
    const [isVisible, setVisibility] = useState(false);

    const didSetId = useCallback((id: number) => {
        setId(id);
    }, []);

    const didChangeVisibility = useCallback(() => {
        setVisibility((prev) => !prev);
    }, []);

    const value: IContextProps = useMemo(() => ({
        id,
        isVisible,
        setId: didSetId,
        changeVisibility: didChangeVisibility,
    }), [id, isVisible]);

    return (
        <LayoutHeaderContext.Provider value={value}>
            {children}
        </LayoutHeaderContext.Provider>
    );
}

/**
 * Компонент-обертка подменю
 */
export function LayoutHeaderPortalRenderer({
    navigation,
    isMobile = false,
    children,
    containerClassName,
    linkClassName,
}: IPortalProps) {
    const { id } = useContext(LayoutHeaderContext);
    const currentMenu = useMemo(() => navigation?.find(item => item.id === id), [ navigation, id ]);
    if (isMobile) {
        return (
            <div className={containerClassName}>
                <div>{children}</div>
                <LayoutHeaderSubMenu title={currentMenu?.title}>
                    {currentMenu?.submenu_items?.map(item => (
                        <a
                            key={item.title}
                            href={item.url}
                            className={linkClassName}
                        >
                            {item.title}
                        </a>
                    ))}
                </LayoutHeaderSubMenu>
            </div>
        );
    }

    return children;
}