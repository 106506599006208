import React, { useCallback } from 'react';
import iconUser from '@frontend/jetlend-assets/icons/icon--user.svg';
import iconChevron from '@frontend/jetlend-assets/icons/icon-chevron--down.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import Tooltip from '../../ui/TooltipIcon/Tooltip';
import {
    isMobile,
    useWindowSize,
} from '@ui/utils/responsive';

import styles from './selectUserButton.module.scss';

export interface IProps extends React.PropsWithChildren {
    /**
     * Заголовок
     */
    name: React.ReactNode|JSX.Element;
    /**
     * Подзаголовок
     */
    sub?: React.ReactNode|JSX.Element;
    /**
     * Иконка
     */
    image?: string;

    /**
     * Флаг, показывающий, что кнопка заблокирована
     */
    disabled?: boolean;
    /**
     * Флаг, показывающий, что кнопка в процессе загрузки
     */
    loading?: boolean;
    /**
    * Всплывающая подсказка
    */
    hint?: string;
    /**
     * Флаг, показывающий, открыто ли внешнее выпадающее меню
     */
    isOpen?: boolean;
    /**
     * Бадж над иконкой пользователя
     */
    badge?: React.ReactNode;
    /**
     * Обработчик клика
     */
    onClick?: () => void;
    /**
     * Обработчик закрытия
     */
    onClose?: () => void;
}

/**
 * Кнопка выбора пользователя
 */
export default function SelectUserButton({
    name,
    sub,
    image,
    disabled,
    loading,
    isOpen,
    onClose,
    onClick,
    children,
    hint = 'Авторизованный пользователь',
    badge,
}: IProps) {
    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    const didButtonClicked = useCallback(() => {
        if (disabled) {
            return;
        }

        if (isOpen && onClose) {
            onClose();
            return;
        }

        if (!isOpen && onClick) {
            onClick();
        }
    }, [disabled, isOpen, onClose, onClick]);

    if (loading) {
        return;
    }

    return (
        <Tooltip title={hint} disabled={isMobileScreen}>
            <div className={styles['container']}>
                <button className={styles['button']} onClick={didButtonClicked} type="button">
                    <div className={styles['button__badge-wrapper']}>
                        <div className={styles['button__wrapper']}>
                            <img
                                className={styles['button__icon']}
                                src={getAssetSrc(image ?? iconUser)}
                                alt=""
                            />
                        </div>
                        <div className={styles['button__badge-icon']}>
                            {badge}
                        </div>
                    </div>
                    <div className={styles['button__content']}>
                        <div className={styles['button__name']}>
                            {name}
                            <img src={getAssetSrc(iconChevron)} alt="" />
                        </div>
                        {sub && (
                            <div className={styles['button__sub']}>
                                {sub}
                            </div>
                        )}
                    </div>

                </button>
                {children && React.Children.count(children) > 0 && (
                    <div className={styles['button__tail']}>
                        {children}
                    </div>
                )}
            </div>
        </Tooltip>
    );
}
