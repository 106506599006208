/**
 * TODO
 * @category Utils
 */
export default function maskText(input: string): string {
    if (!input) {
        return input;
    }

    return input.replaceAll(/[^\s.]/g, '*');
}
