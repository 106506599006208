import React, { ReactNode } from 'react';
import styles from './dropdownActions.module.scss';
import { cnx } from '../../utils/classNameUtils';

interface IProps {
    children: ReactNode;

    onClick?: (e: React.MouseEvent) => void;
    className?: string;
}

const DropdownActionsButton = (props: IProps) => {
    const {
        children,
        onClick,
        className,
    } = props;

    const buttonClassName = cnx([
        styles['button'],
        className,
    ]);

    return (
        <button type="button" onClick={onClick} className={buttonClassName}>
            {children}
        </button>
    );
};

export default DropdownActionsButton;
