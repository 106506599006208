import React from 'react';
import HeaderButton from '@frontend/jetlend-web-ui/src/layout/HeaderButton';
import iconLogout from '@frontend/jetlend-assets/icons/icon--logout.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import connectToForm from '@frontend/jetlend-core/src/ducks/connectToForm';
import { BaseApiResponse } from '@frontend/jetlend-core/src/models/api';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { commonLogoutUserFormHandler } from '@app/ducks/common/login';
import { IAccountInfoApiModel } from '@app/models/common/account';

export interface IProps {
    /**
     * Объект с информацией об аккаунте
     */
    account: IAccountInfoApiModel;
    /**
     * Тип кнопки
     */
    type?: 'button' | 'icon';
}


export const submitWithAccount = commonLogoutUserFormHandler.dispatchExt((values, props: IProps) => ({
    ...values,
    clientType: props.account.client_type,
}));

/**
 * Кнопка выхода из аккаунта
 */
export default connectToForm<IProps, BaseApiResponse>(function ConnectedLogoutButton({
    handleSubmit,
    type = 'icon',
}) {
    if (type === 'button') {
        return (
            <Button
                text="Выход"
                type="secondary"
                noSpacing
                icon={getAssetSrc(iconLogout)}
                onClick={handleSubmit(submitWithAccount)}
            />
        );
    }

    return (
        <HeaderButton
            hint="Выход"
            type="button"
            icon={getAssetSrc(iconLogout)}
            onClick={handleSubmit(submitWithAccount)}
        />
    );
}, commonLogoutUserFormHandler);