import React, { useCallback } from 'react';
import { formatAmount } from '@frontend/jetlend-core/src/formatters/formatUtils';
import maskText from '@frontend/jetlend-core/src/utils/maskText';
import arrowLeft from '@frontend/jetlend-assets/icons/icon--arrow-left-light-gray.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import DropdownActionsButton from '../DropdownActions/DropdownActionsButton';

import styles from './userButton.module.scss';

export type IProps = {
    id: number;
    type: 'investor' | 'borrower' | 'partner';
    name: string;
    balance_amount?: number;
    mask?: boolean;

    onSwitchUser: (type: string, id: number) => void;
}

export const UserButton = (props: IProps) => {
    const {
        onSwitchUser,
        balance_amount,
        name,
        id,
        type,
    } = props;

    const didUserSwitched = useCallback(() => onSwitchUser && onSwitchUser(type, id), [type, id]);

    return (
        <DropdownActionsButton
            onClick={didUserSwitched}
            className={styles['user__button']}
        >
            <div className={styles['main-content__container']} >
                <div className={styles['name']}>{props.mask ? maskText(name) : name}</div>
                {typeof balance_amount === 'number' &&
                    <div className={styles['balance']}>{formatAmount(balance_amount)}</div>
                }
            </div>
            <img className={styles['arrow']} src={getAssetSrc(arrowLeft)} alt="" />
        </DropdownActionsButton>
    );
};