import {
    useState,
    useEffect,
} from 'react';

/**
 * TODO
 * @category Hooks
 */
export function useScroll(enabled = true) {
    const [ isScrolled, setScrolled ] = useState(false);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 0);
        };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('scroll', handleScroll);

        handleScroll();

        return (() => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('scroll', handleScroll);
        });
    }, [ enabled ]);

    return isScrolled;
}