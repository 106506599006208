import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    AccountType,
    ACCOUNT_TYPE_MAP,
    getBadgeClassName,
} from './AccountTypeBadge.helper';
import styles from './accountTypeBadge.module.scss';

export interface IProps {
    type: AccountType;
    link?: string;
    href?: string;
}

const AccountTypeBadge: React.FC<IProps> = props => {
    const {
        link,
        type,
        href,
    } = props;
    const text = ACCOUNT_TYPE_MAP[props.type];

    if (!text) {
        return;
    }

    // Don't render badge when it not supported by Enum
    if (!Object.values(AccountType).includes(type)) {
        return;
    }

    const className = buildClassNames(styles, [
        'badge',
        getBadgeClassName(props.type),
    ]);

    const content = <span>{text}</span>;

    if (href) {
        return <a className={className} href={href}>{content}</a>;
    }

    return (link
        ? (
            <Link className={className} to={link} onClick={e => e.stopPropagation()}>
                {content}
            </Link>
        )
        : <div className={className}>{content}</div>
    );
};

export default AccountTypeBadge;