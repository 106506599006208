'use client';

import React, {
    useRef,
    useState,
    useCallback,
    useLayoutEffect,
} from 'react';
import ResponsiveMenuButton from './ResponsiveMenu.Button';
import styles from './responsiveMenu.module.scss';
import { useResizeObserver } from '@ui/hooks/useResizeObserver';
import HeaderBurgerButton from '../../layout/HeaderBurgerButton';
import PopupWindow from '../../ui/PopupWindow/PopupWindow';
import { buildClassNames } from '../../utils/classNameUtils';
import { useScroll } from '@ui/hooks/useScroll';
import { useScrollWidth } from '@ui/hooks/useScrollWidth';
import {
    isMobile,
    useWindowSize,
} from '../../utils/responsive';
import Modal from '../../ui/modals/Modal';

export interface IProps extends React.PropsWithChildren {
    logo?: JSX.Element|React.ReactNode;
    smallLogo?: JSX.Element|React.ReactNode;
    actions?: JSX.Element|React.ReactNode;
    fixed?: boolean;
}

const NAVIGATION_BUTTONS_SELECTOR = `.${styles['main__navigation']} > .${styles['button']}`;
const MENU_BUTTONS_SELECTOR = `.${styles['button']}`;

const ResponsiveMenu: React.FC<IProps> = props => {
    const isScrolled = useScroll(props.fixed);
    useScrollWidth();

    const mainContainerRef = useRef<HTMLDivElement>(null);
    const menuNavigationRef = useRef<HTMLDivElement>(null);

    const [ collapsed, setCollapsed ] = useState(false);
    const [ hiddenAtIndex, setHiddenAtIndex ] = useState(-1);
    const [ menuExpanded, setMenuExpanded ] = useState(false);

    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    useLayoutEffect(() => {
        if (!menuExpanded) {
            return;
        }

        const menuNavigationElement = menuNavigationRef.current;
        if (!menuNavigationElement) {
            return;
        }

        const menuButtons = Array.from(menuNavigationElement.querySelectorAll<HTMLElement>(MENU_BUTTONS_SELECTOR));
        for (let buttonIndex = 0; buttonIndex < menuButtons.length; buttonIndex++) {
            const menuButtonElement = menuButtons[buttonIndex];

            const shouldButtonHidden = buttonIndex >= hiddenAtIndex;

            menuButtonElement.classList.toggle(styles['button--hidden'], shouldButtonHidden);

            if (menuButtonElement.children[0] && shouldButtonHidden) {
                menuButtonElement.children[0].setAttribute('data-button--hidden', 'true');
            }
        }
    }, [ hiddenAtIndex, menuExpanded, menuNavigationRef ]);

    const [ width ] = useResizeObserver(mainContainerRef, ({ width }) => {
        const containerElement = mainContainerRef.current;

        if (!containerElement) {
            return;
        }

        // Reduce available width by 60 by able place menu button
        const minAvailableMenuWidth = width - 60;

        const navigationButtons = Array.from(containerElement.querySelectorAll<HTMLElement>(NAVIGATION_BUTTONS_SELECTOR));

        let collapsed = false;
        let renderedWidth = 0;
        for (let buttonIndex = 0; buttonIndex < navigationButtons.length; buttonIndex++) {
            const buttonElement = navigationButtons[buttonIndex];

            const buttonWidth = buttonElement.offsetWidth;
            const shouldButtonHidden = buttonWidth > 0 && renderedWidth + buttonWidth >= minAvailableMenuWidth;

            buttonElement.classList.toggle(styles['button--hidden'], shouldButtonHidden);

            renderedWidth += buttonWidth;

            if (shouldButtonHidden && !collapsed) {
                setHiddenAtIndex(buttonIndex);
                collapsed = true;
            }
        }

        setCollapsed(collapsed);
        if (!collapsed) {
            setMenuExpanded(false);
        }
    });

    const didMenuToggle = useCallback(() => setMenuExpanded(expanded => !expanded), []);
    const didMenuClosed = useCallback(() => setMenuExpanded(false), []);

    const containerClassName = buildClassNames(styles, [
        'container',
        props.fixed && 'container--fixed',
        isScrolled && 'container--scrolled',
    ]);

    const mainClassName = buildClassNames(styles, [
        'main',
        collapsed && 'main--collapsed',
    ]);

    return (
        <>
            <header className={containerClassName}>
                <div className={styles['logo']}>
                    <div className={styles['logo--small']}>
                        {props.smallLogo ?? props.logo}
                    </div>
                    <div className={styles['logo--normal']}>
                        {props.logo}
                    </div>
                </div>
                <div ref={mainContainerRef} className={mainClassName}>
                    <div className={styles['main__navigation']}>
                        {props.children}
                    </div>
                    {collapsed && !isMobileScreen && (
                        <div className={styles['main__menu']}>
                            <PopupWindow
                                isOpen={menuExpanded}
                                onClose={didMenuClosed}
                                placement="bottom"
                                content={(
                                    <div ref={menuNavigationRef} className={styles['popup-container']}>
                                        {props.children}
                                    </div>
                                )}
                            >
                                <HeaderBurgerButton
                                    inverted
                                    noMargin
                                    opened={menuExpanded}
                                    onClick={didMenuToggle}
                                />
                            </PopupWindow>
                        </div>
                    )}
                    <div className={buildClassNames(styles, ['main__menu', 'main__menu--mobile'])}>
                        <HeaderBurgerButton inverted opened={menuExpanded} onClick={didMenuToggle} />
                    </div>
                </div>
                {props.actions && (
                    <div className={styles['actions']}>
                        {props.actions}
                    </div>
                )}
            </header>
            {props.fixed &&
                <div className={styles['container-placeholder']} />
            }
            {isMobileScreen && (
                <Modal
                    headerClassName={styles['modal__header']}
                    title={(
                        <div>
                            <div className={styles['logo']}>
                                {props.logo}
                            </div>
                        </div>
                    )}
                    noBorder
                    isOpen={menuExpanded}
                    closable
                    onClosed={didMenuClosed}
                >
                    <div className={styles['modal-container']}>
                        {props.children}
                    </div>
                    {props.actions && (
                        <>
                            <hr className={styles['modal__actions-divider']} />
                            <div className={styles['modal__actions']}>
                                {props.actions}
                            </div>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export {
    ResponsiveMenuButton,
};

export default ResponsiveMenu;