import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/SFProDisplay/SFProDisplay.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./Black/SFProDisplay-Black.woff2\",\"weight\":\"900\"},{\"path\":\"./Bold/SFProDisplay-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"./Heavy/SFProDisplay-Heavy.woff2\",\"weight\":\"800\"},{\"path\":\"./Light/SFProDisplay-Light.woff2\",\"weight\":\"300\"},{\"path\":\"./Medium/SFProDisplay-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"./Regular/SFProDisplay-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"./SemiBold/SFProDisplay-SemiBold.woff2\",\"weight\":\"600\"},{\"path\":\"./Thin/SFProDisplay-Thin.woff2\",\"weight\":\"100\"},{\"path\":\"./Ultralight/SFProDisplay-Ultralight.woff2\",\"weight\":\"200\"}],\"display\":\"swap\",\"variable\":\"--default-font-name\"}],\"variableName\":\"SFProDisplayFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/common/LayoutHeader/LayoutHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/common/LayoutHeader/LayoutHeaderPortalRenderer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/common/RegistrationModal/RegistrationModal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/LayoutFooter/layoutFooter.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/LayoutFooter/LayoutFooterSectionPortal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/common/YandexMetrika/YandexMetrikaClient.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/ui/SocialButton/SocialButton.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/icons/bks-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/dzen-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/telegram-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/tpulse-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/youtube-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/store/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/main.scss");
