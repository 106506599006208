import React, { ReactNode } from 'react';
import PopupWindow, { IProps as IPopupWindowProps } from '../../ui/PopupWindow/PopupWindow';
import styles from './dropdownActions.module.scss';
import DropdownActionsButton from './DropdownActionsButton';
import DropdownActionsLink from './DropdownActionsLink';
import DropdownActionsSection from './DropdownActionsSection';
import { buildClassNames } from '../../utils/classNameUtils';

export interface IProps extends Omit<IPopupWindowProps, 'content'>, React.PropsWithChildren {
    button: ReactNode;
    size?: 'small'|'default';
}

const DropdownActions = (props: IProps) => {
    const {
        children,
        button,
        withoutArrow = true,
        placement = 'bottom-start',
        size = 'default',
        ...restParams
    } = props;

    const content = (
        <div
            className={buildClassNames(styles, [
                'content__container',
                `content__container--size-${size}`,
            ])}
        >
            {children}
        </div>
    );

    return (
        <PopupWindow
            {...restParams}
            placement={placement}
            className={styles['popup__container']}
            content={content}
            withoutArrow={withoutArrow}
        >
            {button}
        </PopupWindow>
    );
};

DropdownActions.Button = DropdownActionsButton;
DropdownActions.Link = DropdownActionsLink;
DropdownActions.Section = DropdownActionsSection;

export default DropdownActions;
