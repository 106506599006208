import React from 'react';
import styles from './responsiveMenu.module.scss';
import { buildClassNames } from '../../utils/classNameUtils';

export interface IProps {
    title: string;
    href: string;
    rel?: string;
    active?: boolean;
}

const ResponsiveMenuButton: React.FC<IProps> = props => {
    const buttonClassName = buildClassNames(styles, [
        'button',
        props.active && 'button--active',
    ]);

    return (
        <a className={buttonClassName} href={props.href} rel={props.rel}>
            {props.title}
        </a>
    );
};

export default ResponsiveMenuButton;
