import {
    useEffect,
    useState,
} from 'react';

function getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    // needed for WinJS apps
    (outer.style as any).msOverflowStyle = 'scrollbar';

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = 'scroll';

    // add innerDiv
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    // remove div
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

/**
 * TODO
 * @category Hooks
 */
export function useScrollWidth() {
    const [ scrollWidth, setScrollWidth ] = useState(0);

    useEffect(() => {
        if ((window as any).__useScrollWidthRegistered === true) {
            false;
        }

        const windowResizeHandler = () => {
            const width = getScrollbarWidth();
            setScrollWidth(width);

            document.documentElement.style.setProperty('--common-scroll-width', `${width.toFixed(0)}px`);
        };

        window.addEventListener('resize', windowResizeHandler);
        windowResizeHandler();
        (window as any).__useScrollWidthRegistered = true;

        return () => {
            window.removeEventListener('resize', windowResizeHandler);
            (window as any).__useScrollWidthRegistered = false;
        };
    }, []);

    return scrollWidth;
}