import React, { useEffect }  from 'react';

export interface IObserverOptions {
    /**
     * изменения в непосредственных детях node
     */
    childList?: boolean;
    /**
     * изменения во всех потомках node
     */
    subtree?: boolean;
    /**
     * изменения в атрибутах node
     */
    attributes?: boolean;
    /**
     * массив имён атрибутов, чтобы наблюдать только за выбранными
     */
    attributeFilter?: string[];
    /**
     * наблюдать ли за node.data (текстовое содержимое)
     */
    characterData?: boolean;
}

const DEFAULT_OPTIONS: IObserverOptions = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
};

/**
 * React Hook, отслеживает изменения внесенные в DOM
 * @param ref ссылка на DOM элемент
 * @param callback колбэк-функция, которая вызывается после изменения наблюдаемого элемента
 * @param options настройка «на какие изменения реагировать»
 * @category Hooks
 * @example
 * useMutationObserver(ref, callback, {childList: false, attributeFilter: ['data-test']});
 */
export function useMutationObserver(
    ref: React.RefObject<HTMLDivElement>,
    callback: () => void,
    options = DEFAULT_OPTIONS
): void {
    useEffect(() => {
        if (ref.current) {
            const observer = new MutationObserver(callback);

            observer.observe(ref.current, options);

            return () => observer.disconnect();
        }
    }, [callback, options]);
};